'use client'
import StyledComponentsRegistry from "@/lib/registry";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import GlobalStyle from "@/styles/globalStyle";
import './globals.css';
import Menu from "@/components/Menu";
import {useEffect, useRef, useState} from "react";
import Footer from "@/components/Footer";
import {useGSAP} from "@gsap/react";
import {usePathname} from "next/navigation";
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {splitLeft, SplitUpNew, SplitUpNewWord} from "@/components/animation/TextAnimationRegular";
import Preloader from "@/components/Preloader";
import {FadeUp} from "@/components/animation/FadeUp";
import Popup from "@/components/Popup";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";


export default function RootLayout({children}) {
    const [offset, setOffset] = useState()
    const pathName = usePathname();
    const [isLoading, setIsLoading] = useState(true); // Add a state for loading

    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
    useEffect(() => {

        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        setOffset(document.querySelector(' .container')?.offsetLeft)
    }, [])



    const el = useRef();
    let smoother;
    useGSAP(() => {
        if (typeof window !== 'undefined' && window.innerWidth > 767) {
            smoother = ScrollSmoother.create({
                smooth: 3, // how long (in seconds) it takes to "catch up" to the native scroll position
                effects: true, // looks for data-speed and data-lag attributes on elements
                smoothTouch: 0.1,
            });
        }
        ScrollTrigger.refresh();
        return () => {
            smoother?.kill();
        };
    }, [pathName]);


    useEffect(() => {
        window.scroll(0, 0);
        if (window.location.hash) {
            gsap.to(window, { duration: 1, scrollTo: window.location.hash });
        }
    },[pathName]);

    const getMenuClass = () => {
        if (pathName.startsWith('/news/') || pathName.startsWith('/privacy-policy')) {
            return 'menu-bg-color';
        }
        // Return default class if not in the specified routes
        return 'default-menu-class';
    };


    FadeUp();
    // SplitUp();
    // TextAnimation()
    SplitUpNew()
    splitLeft()
    SplitUpNewWord()
    return (
        <html lang="en">
        <head>
            <link rel="icon" type="image/png" href="/images/static/fav.png"/>
            <meta content="#000000" name="theme-color"/>
        </head>
        <body>
        <StyledComponentsRegistry>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                scriptProps={{
                    async: false,
                    defer: false,
                    appendTo: 'head',
                }}
            >
            {isLoading && <Preloader />}
            <div id="main-root">
                {/*<PageTransition/>*/}
                <GlobalStyle/>
                <ToastContainer/>
                {pathName === '/' && <Popup/>}
                <Menu offset={offset} getMenuClass={getMenuClass}/>
                <div ref={el} id="smooth-wrapper">
                    <div id="smooth-content">
                        {children}
                        <Footer/>
                    </div>
                </div>

            </div>
            </GoogleReCaptchaProvider>
        </StyledComponentsRegistry>
        </body>
        </html>
    );
}
