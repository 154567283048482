import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect} from "react";
import {usePathname} from "next/navigation";

export const FadeUp = () => {
    const location = usePathname ();
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        if (window.innerWidth > 767) {
        let allAnim = document.querySelectorAll('.fade-up');
        allAnim.forEach((el, index) => {
            gsap.fromTo(el, {
                autoAlpha: 0,
                y: 100,
                ease: "none",
            }, {
                y: 0,
                autoAlpha: 1,
                ease: "power2",
                duration: 1.5,
                scrollTrigger: {
                    id: `${index + 1}`,
                    trigger: el,
                    start: 'top bottom+=50',
                    toggleActions: 'play none none none',
                    once: true,
                }
            })
        })
        return (() => {
            allAnim = ''
        })
        }
    }, [location]);
}

