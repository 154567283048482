import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {gsap} from "gsap";
import axios from "axios";

const MyComponent = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        // Fetch data once the component mounts
        axios.get('https://project.bestinbd.com/projects/web/2406ATD/api/get-req-data/sections?type=slug&value=home&get_section=yes&image=yes&post=yes&file=yes&gallery=no')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let tl = gsap.timeline();

        // Show popup initially
        tl.to('.promotional-popup', {
            duration: 0,
            display: "flex",
            delay: 2
        }).to('.promotional-popup', {
            opacity: 1
        }).to(".popup-close", {
            duration: 0.3,
            opacity: 1
        });

    }, [data]);

    const popup = data?.data?.sections?.find(f => f?.section_data?.template === 'popup');

    const handleClose = () => {
        let tl2 = gsap.timeline();
        tl2.to('.promotional-popup', {
            opacity: 0,
            duration: 0.4
        }).to('.promotional-popup', {
            display: "none",
            duration: 0
        }).to(".popup-close", {
            duration: 0,
            opacity: 0
        });
    };
    return (

        <>
            {popup?.images?.list?.[0]?.full_path && (
                <StyledComponent className="promotional-popup">
                    <div className="promotional-popup__img">
                        <img src={popup?.images?.list?.[0]?.full_path} alt=""/>
                        <a href="/"></a>
                        <img
                            className="popup-close"
                            src="/images/static/popup-close.svg"
                            alt=""
                            onClick={handleClose}
                        />
                    </div>
                </StyledComponent>
            )}
        </>

    );
};


const StyledComponent = styled.section`
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  align-items: center;
  display: none;
  opacity: 0;

  .promotional-popup__img {
    width: 60%;
    margin: auto;
    display: flex;
    align-items: center;
    //height: fit-content;
    position: relative;
    justify-content: center;
    height: 80%;

    .popup-close {
      position: absolute;
      top: 0;
      //right: 5px;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
      right: 0;
      margin: auto;
      filter: invert(130%) sepia(0%) saturate(5%) hue-rotate(81deg) brightness(97%) contrast(108%);
    }

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    img {
      max-width: 65%;
      object-fit: contain;
    }
  }

  @media (max-width: 767px) {
    .promotional-popup__img {
      width: 90%;

      img {
        min-width: 90%;
      }
    }
  }
`;

export default MyComponent;
