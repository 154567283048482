import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {usePathname} from "next/navigation";
import {SplitText} from "gsap/SplitText";
import {useGSAP} from "@gsap/react";
import {useEffect} from "react";


export const ParallaxXX = () => {
    const pathName = usePathname();
    useGSAP(() => {
        gsap.utils.toArray(".parallax-x").forEach((item, i) => {
            let parallaxSize = item.getAttribute("data-size");
            gsap.to(item, {
                xPercent: parallaxSize || -40,
                ease: "none",
                scrollTrigger: {
                    // start: "clump(bottom 0)",
                    trigger: item,
                    // markers: true,
                    scrub: true,
                },
            });
        });
        // return () => {
        //     ScrollTrigger.getAll().forEach(trigger => {
        //         trigger.kill(); // Remove all ScrollTriggers
        //     });
        // };
    }, [pathName]);
};
export const ParallaxX = () => {
    const pathName = usePathname();
    useGSAP(() => {
        gsap.utils.toArray(".parallax-xx").forEach((item, i) => {
            let parallaxSize = item.getAttribute("data-size");
            gsap.to(item, {
                xPercent: parallaxSize || -20,
                duration:1,
                ease: "none",
                scrollTrigger: {
                    // start: "clump(bottom 0)",
                    trigger: item,
                    // markers: true,
                    scrub: true,
                },
            });
        });
        // return () => {
        //     ScrollTrigger.getAll().forEach(trigger => {
        //         trigger.kill(); // Remove all ScrollTriggers
        //     });
        // };
    }, [pathName]);
};

export const ParallaxXR = () => {
    const pathName = usePathname();
    useGSAP(() => {
        gsap.utils.toArray(".parallax-xr").forEach((item, i) => {
            let parallaxSize = item.getAttribute("data-size");
            gsap.to(item, {
                xPercent: parallaxSize || 30,
                ease: "none",
                scrollTrigger: {
                    // start: "clump(bottom 0)",
                    trigger: item,
                    // markers: true,
                    scrub: true,
                },
            });
        });
        // return () => {
        //     ScrollTrigger.getAll().forEach(trigger => {
        //         trigger.kill(); // Remove all ScrollTriggers
        //     });
        // };
    }, [pathName]);
};

export const FadeUp = () => {
    const pathName = usePathname();
    useGSAP(() => {
        gsap.utils.toArray(".fade-up").forEach((item, i) => {
            gsap.from(item, {
                y: "50px",
                ease: "power4.out",
                duration: 1,
                delay: 0.01,
                scrollTrigger: {
                    // start: "clump(bottom 0)",
                    trigger: item,
                    toggleActions: "restart none none reset",
                },
            });
        });
        return () => {
            ScrollTrigger.getAll().forEach(trigger => {
                trigger.kill(); // Remove all ScrollTriggers
            });
        };
    }, [pathName]);
};


export const SplitUpNew = (data) => {
    const router = usePathname();
    gsap.registerPlugin(SplitText, ScrollTrigger);

    useGSAP(() => {
        // Check if the screen width is greater than 767px
        if (window.innerWidth > 767) {
            gsap.utils.toArray('.split-up').forEach((item, i) => {
                const originalHTML = item.innerHTML;
                const originalLetterSpacing = window.getComputedStyle(item).letterSpacing;

                const parentSplit = new SplitText(item, {
                    linesClass: "split-parent",
                });

                const childSplit = new SplitText(item, {
                    type: "words, chars, lines",
                    linesClass: "split-child",
                });

                gsap.from(parentSplit.lines, {
                    duration: 1,
                    yPercent: 100,
                    scrollTrigger: {
                        trigger: item,
                        toggleActions: 'restart none none reset',
                    }
                });
            });
        }
    }, [router]);
};

export const SplitUpNewWord = (data) => {
    const router = usePathname();
    gsap.registerPlugin(SplitText, ScrollTrigger);

    useGSAP(() => {
        // Check if the screen width is greater than 767px
        if (window.innerWidth > 767) {
            gsap.utils.toArray('.split-word').forEach((item) => {
                // Split the text into characters only
                const splitText = new SplitText(item, {
                    type: "words",
                    charsClass: "split-char", // Optional: Add a class for styling
                });

                // Animate the characters
                gsap.from(splitText.words, {
                    duration: 1,
                    yPercent: 100,
                    opacity: 0,
                    stagger: 0.05, // Adjust the stagger for timing
                    scrollTrigger: {
                        trigger: item,
                        toggleActions: 'restart none none reset',
                    }
                });
            });
        }
    }, [router]);
};

export const splitLeft = () => {
    const router = usePathname();
    gsap.registerPlugin(SplitText, ScrollTrigger);
    useEffect(() => {
        // Ensure SplitText is initialized properly
        const elements = gsap.utils.toArray(".split-left");

        // Initialize SplitText for each element
        const splitInstances = elements.map((item) => {
            return new SplitText(item, {
                type: "chars,words",
                reduceWhiteSpace: true,
            });
        });

        // Set up GSAP animations for each element
        elements.forEach((item, i) => {
            const chars = splitInstances[i].chars;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "play none none none", // Play once, no reversing
                    start: "top 80%", // Adjust this as needed
                    end: "bottom 20%", // Control when trigger ends
                    once: true, // Ensures the animation happens only once
                }
            });

            tl.from(chars, {
                duration: 2,
                x: "10vw",
                stagger: 0.025,
                scale: 0.95,
                ease: "expo.out",
            })
                .from(chars, {
                    duration: 0.8,
                    opacity: 0,
                    stagger: 0.025,
                    ease: "power4.out",
                }, 0); // Start the opacity animation with the x animation
        });

    }, [router]);
};